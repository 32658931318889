import videoBG from "@images/resources/video-bg-1-1.jpg";
import appFeature1 from "@images/resources/cta-2-moc-1.png";
import testimonial1 from "@images/testimonials/testimonials-1-img-1.png";
import testimonial2 from "@images/testimonials/testimonials-1-img-2.png";
import testimonialShape from "@images/testimonials/testimonials-1-shape-1.png";
import testimonialShape2 from "@images/testimonials/testimonials-1-shape-2.png";
import appshot1 from "@images/app-shots/app-shot-n-1-1.png";
import appshot2 from "@images/app-shots/app-shot-n-1-2.png";
import appshot3 from "@images/app-shots/app-shot-n-1-3.png";
import appshot4 from "@images/app-shots/app-shot-n-1-4.png";
import appshot5 from "@images/app-shots/app-shot-n-1-5.png";
import blog1 from "@images/blog/blog-2-1.jpg";
import blog2 from "@images/blog/blog-2-2.jpg";
import blog3 from "@images/blog/blog-2-3.jpg";
import blog4 from "@images/blog/blog-2-4.jpg";
import blog5 from "@images/blog/blog-2-5.jpg";
import blog6 from "@images/blog/blog-2-6.jpg";
export const SERVICE_DATA = {
  sectionTitle: {
    title: "Wicebot Features",
    text: "Our App Feature Lists"
  },
  posts: [
    {
      extraClassName: "",
      icon: "piloz-writing",
      title: "Easy to use",
      text: "This interface offers a precise and efficient way to access information or perform actions, including complex and technical tasks by using command."
    },
    /* {
       extraClassName: "bg-2",
       icon: "piloz-shield",
       title: "Fully secure",
       text: "Lorem ipsum is are many variations of pass majy."
     },*/
    {
      extraClassName: "bg-3",
      icon: "piloz-user",
      title: "Real time",
      text: "The real-time nature of the wicebot.guru ensures prompt and up-to-date information for the user, making it useful for various applications."
    },
    {
      extraClassName: "bg-4",
      icon: "piloz-gear",
      title: "Free trial",
      text: "With 100 request/month free trial period,users can experience the full range of Wicebot's services and see how it can improve their workflow on Slack."
    }
  ]
};

export const CALL_TO_ACTION_DATA = {
  sectionTitle: {
    text: "Our App Feature Lists",
    title: "Powerful App for \n Your Mobile"
  },
  paragraph:
    "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eufugiat nulla pariatur. Lorem Ipsum is simply dummy text of the printing laborum  perspiciatis unde.",
  posts: [
    {
      title: "Responsive \n Design",
      extraClassName: " ",
      icon: "piloz-devices"
    },
    {
      title: "Online \n Marketing",
      icon: "piloz-networking",
      extraClassName: "color-2"
    }
  ],
  button: {
    link: "#",
    label: "Discover More"
  }
};


export const VIDEO_DATA = {
  title: "Watch Our Video",
  image: videoBG,
  videoID: "Kl5B6MBAntI"
};


export const APP_FEATURE_DATA = {
  sectionTitle: {
    title: "Discover Tools for\nYour Customers",
    text: "Our App Feature Lists"
  },
  image: appFeature1,
  paragraph:
    "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum lorem ipsum is simply free.",
  options: [
    {
      label: "Refresing to get such a personal touch."
    },
    {
      label: "Duis aute irure dolor in reprehenderit in voluptate."
    },
    {
      label: "Velit esse cillum dolore eu fugiat nulla pariatur."
    }
  ],
  button: { link: "#", label: "Discover More" }
};

export const PRICING_DATA = {
  sectionTitle: {
    title: "Our Pricing Plans",
    text: "Choose Our Pricing"
  },
  monthly: [
    {
      count: "01",
      amount: "$0.00",
      name: "100 request/month",
      users: "",
      extraClassName: "color-1",
      options: [
        { label: "Extra features" },
        { label: "Lifetime free support" },
        { label: "Upgrate options" },
        { label: "Full access" }
      ],
      button: {
        label: "Add to Slack",
        link: "#"
      }
    },
    {
      count: "02",
      amount: "$4.99",
      name: "1000 request/month",
      users: "",
      extraClassName: "color-2",
      options: [
        { label: "Extra features" },
        { label: "Lifetime free support" },
        { label: "Upgrate options" },
        { label: "Full access" }
      ],
      button: {
        label: "Add to Slack",
        link: "#"
      }
    },
    {
      count: "03",
      amount: "$8.99",
      name: "3000 request/month",
      users: "",
      extraClassName: "color-3",
      options: [
        { label: "Extra features" },
        { label: "Lifetime free support" },
        { label: "Upgrate options" },
        { label: "Full access" }
      ],
      button: {
        label: "Add to Slack",
        link: "#"
      }
    }
  ],
  yearly: [
    {
      count: "01",
      amount: "$40.00",
      name: "Premium Pack",
      extraClassName: "color-1",
      options: [
        { label: "Extra features" },
        { label: "Lifetime free support" },
        { label: "Upgrate options" },
        { label: "Full access" }
      ],
      button: {
        label: "Choose Plan",
        link: "#"
      }
    },
    {
      count: "02",
      amount: "$80.00",
      name: "Premium Pack",
      extraClassName: "color-2",
      options: [
        { label: "Extra features" },
        { label: "Lifetime free support" },
        { label: "Upgrate options" },
        { label: "Full access" }
      ],
      button: {
        label: "Choose Plan",
        link: "#"
      }
    },
    {
      count: "03",
      amount: "$100.00",
      name: "Premium Pack",
      extraClassName: "color-3",
      options: [
        { label: "Extra features" },
        { label: "Lifetime free support" },
        { label: "Upgrate options" },
        { label: "Full access" }
      ],
      button: {
        label: "Choose Plan",
        link: "#"
      }
    }
  ]
};



export const TESTIMONIALS_DATA = {
  sectionTitle: {
    text: "Our Testimonials",
    title: "What They Says"
  },
  posts: [
    {
      extraClassName: " ",
      shape: testimonialShape,
      image: testimonial1,
      title: "Isabel Griffith",
      designation: "Customer",
      text:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch."
    },
    {
      extraClassName: "color-2",
      image: testimonial2,
      shape: testimonialShape2,
      title: "Wesley Dixon",
      designation: "Customer",
      text:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch."
    },
    {
      extraClassName: " ",
      image: testimonial1,
      shape: testimonialShape,
      title: "Isabel Griffith",
      designation: "Customer",
      text:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch."
    },
    {
      extraClassName: "color-2",
      image: testimonial2,
      shape: testimonialShape2,
      title: "Wesley Dixon",
      designation: "Customer",
      text:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch."
    },
    {
      extraClassName: " ",
      image: testimonial1,
      shape: testimonialShape,
      title: "Isabel Griffith",
      designation: "Customer",
      text:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch."
    },
    {
      extraClassName: "color-2",
      image: testimonial2,
      shape: testimonialShape2,
      title: "Wesley Dixon",
      designation: "Customer",
      text:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch."
    }
  ]
};



export const APP_SHOT_DATA = {
  sectionTitle: {
    title: "App Screenshots",
    text: "Check Before Choosing"
  },
  posts: [
    {
      image: appshot1
    },
    {
      image: appshot2
    },
    {
      image: appshot3
    },
    {
      image: appshot4
    },
    {
      image: appshot5
    },
    {
      image: appshot1
    },
    {
      image: appshot2
    },
    {
      image: appshot3
    },
    {
      image: appshot4
    },
    {
      image: appshot5
    },
    {
      image: appshot1
    },
    {
      image: appshot2
    },
    {
      image: appshot3
    },
    {
      image: appshot4
    },
    {
      image: appshot5
    }
  ]
};

export const FAQ_DATA = {
  sectionTitle: {
    text: "Frequently Asked Questions",
    title: "Have Any Question?"
  },
  posts: [
    {
      status: true,
      title: "How do I ask Wicebot to do things for me?",
      text:
        "",
      gifUrl: "https://wicebotinactiongifs.s3.us-east-2.amazonaws.com/%40mention.gif"
    },
    {
      status: true,
      title: "Chat with Wicebot, it's easy as pie!",
      text:
        "",
      gifUrl: "https://wicebotinactiongifs.s3.us-east-2.amazonaws.com/conversation.gif"
    },
    {
      status: true,
      title: "Need to code, but don't have the time?",
      text:
        ""
      ,
      gifUrl: "https://wicebotinactiongifs.s3.us-east-2.amazonaws.com/rapidcode.gif"
    },
    {
      status: true,
      title: "From Java to Python, we've got you covered!",
      text:
        ""
      ,
      gifUrl: "https://wicebotinactiongifs.s3.us-east-2.amazonaws.com/translatecode.gif"
    },
    {
      status: true,
      title: "How does Wicebot summarize a thread conversation?",
      text:
        "Wicebot, the intelligent Slack bot that summarizes your team's conversations in a clear and concise format. With Wicebot, you can quickly catch up on important discussions, stay up-to-date on project progress, and never miss a critical message again."
    }
  ]
};



export const BLOG_DATA = {
  sectionTitle: {
    text: "From the Blog Posts",
    title: "News & Articles"
  },
  posts: [
    {
      name:"Effortlessly",
      date: "20 Feb, 2023",
      commentCount: "0",
      title: "Wicebot: Effortlessly Enhance Efficiency",
      text:
        "Effortlessly Enhance Your Workplace Efficiency with Wicebot's Slack Integration",
      link: {
        path: "/effortlessly-blog-details",
        label: "Read More"
      }
    },
    {
      name:"TranslateCode",
      date: "22 Feb, 2023",
      commentCount: "0",
      title: "Wicebot: Breaking Language Barriers in Programming",
      text:
        "Easily translate code from one language to another and breaking language barriers.",
      link: {
        path: "/translatecode-blog-details",
        label: "Read More"
      }
    },
    {
      name:"WriteCode",
      image: blog1,
      date: "23 Feb, 2023",
      commentCount: "0",
      title: "Wicebot: Converting Instructions to Code",
      text:
        "AI program that simplifies programming for beginners and saves time for experienced developers.",
      link: {
        path: "/writecode-blog-details",
        label: "Read More"
      }
    }
  ]
};
