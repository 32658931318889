import React from "react";
import { Link } from "gatsby";
import EffortlesslyIcon from "../../assets/images/blog/icon.card.inline.svg";
import TranslateCodeIcon from "../../assets/images/blog/translatecode.card.inline.svg";
import WriteCodeIcon from "../../assets/images/blog/writecode.card.inline.svg"
const BlogCard = ({
  name,
  extraClassName,
  date,
  commentCount,
  title,
  text,
  link
}) => {

  const findBlog = () => {
    if(name === "Effortlessly"){
      return <EffortlesslyIcon />
    }else if(name === "TranslateCode"){
      return <TranslateCodeIcon />
    }else {
      return <WriteCodeIcon/>
    }
  }

  return (
    <div className={`blog-one__single ${extraClassName}`}>
      <div className="blog-one__image">
        {findBlog()}
        <div className="blog-hover-box">
          <div className="box">
            <div className="icon-box">
              <Link to={link.path}>
                  <i className="far fa-link"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-one__content">
        <div className="blog-one__meta">
          <a href="#">
            <i className="far fa-calendar-alt"></i> {date}
          </a>
{/*          <a href="#">
            <i className="far fa-comments"></i> {commentCount} comments
          </a>*/}
        </div>
        <h3>
          <Link to={link.path}>
          {title}
          </Link>
        </h3>
        <div className="blog-one__text">
          <p>{text}</p>
        </div>
        <Link to={link.path} className="thm-btn blog-one__btn">
            <span>{link.label}</span>
        </Link>
      </div>
    </div>
  );
};

export default BlogCard;
